import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import TabPanel from "../common/TabPanel";
import { 
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core"

const useStyles = makeStyles({  
  tabBar: {
    background: "white",    
  },
  tab: {
    border: "1px solid #cdcdcd",
    borderBottom: "none",
    marginRight: 5,
    color: "black",
    textTransform: "capitalize",
    fontWeight: "bold",
  },  
  item: {
    border: "1px solid #cdcdcd",
  },
  text: {
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    margin: 0,
    color: "#888"
  },

  listRoot: {
    width: "100%",
    maxHeight: 300,
    overflow: "auto",
  },
  listItem: {
    border: "1px solid #cdcdcd",
    marginBottom: 10,
    paddingLeft: 5,
  },
  selected: {
    borderRight: "8px solid #e96c11",        
  },
  listItemText:{
    fontWeight: 700,
    color: "#888"
  }
});
const TabsComponent = (props) => {
  const { data, selectedIndex } = props;
  const [allData, setAllData] = useState({});

  useEffect(() => {
    let obj;
    if(data) {
      let tempData = data.filter(item => item.Key.includes(`materiais-objeto-${selectedIndex + 1}`));
      let opcoesList = tempData.filter(item => item.Key.includes("opcao"));
      let list = [];            
      for(let i = 1; i <= (opcoesList.length / 2); i++) {
        let obj = {
          title: tempData.find(item => item.Key.includes(`materiais-objeto-${selectedIndex + 1}-opcao-${i}-title`)).Value,
          text: tempData.find(item => item.Key.includes(`materiais-objeto-${selectedIndex + 1}-opcao-${i}-text`)).Value,          
        }    
        list.push(obj);    
      }
      obj = {
        title: tempData.find(item => item.Key === `materiais-objeto-${selectedIndex + 1}-title`).Value,
        text: tempData.find(item => item.Key === `materiais-objeto-${selectedIndex + 1}-text`).Value,        
        list,
      }      
    }

    setValue(0);
    setAllData(obj);
  }, [data, selectedIndex])
  
  const classes = useStyles();
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="description">
      {allData ? (
        <>
          <h1 className="description-title">{allData.title}</h1>
          <p className="description-text">{allData.text}</p>
          <AppBar className={classes.tabBar} position="static" elevation={0}>
            <Tabs value={value} onChange={handleChange}>
              {allData.list ? allData.list.map((item, index) => (
                <Tab key={index} className={classes.tab} label={item.title} {...a11yProps(0)} />
              )) : null}            
            </Tabs>
          </AppBar>
          {allData.list ? allData.list.map((item, index) => (
            <TabPanel key={index} className={classes.item} value={value} index={index}>        
              <p className={classes.text}>{item.text}</p>        
            </TabPanel>
          )) : null}          
        </>
      ) : null}
      
    </div>  
  )
}

export default TabsComponent;