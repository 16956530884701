import React, { useState, useEffect } from "react";
import client from '../components/utils/client';
import Layout from "../components/Layout/layout";

import Banner from "./common/Banner";
import AreaList from "./materiais/AreaList";
import TabsComponent from "./materiais/TabsComponent";

const MateriaisPage = () => {
  const [data, setData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    const handleGetData = () => {
      client()
        .get("content-group/15")
        .then(res => {          
          let data = res.data || [];          
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          if(data.Contents) {
            setData(data.Contents);
          }
        })
        .catch(err => {
          console.log(err);
        })
    }

    handleGetData();
  }, [])

  return (
    <Layout infoPage={{page: "corte"}}>
      <div className="materiais">
        {data ? (
          <>
            <Banner text={data.find(item => item.Key === "materiais-main-title").Value} />
            <section className="container-60 margin-vertical-100px main-section">
              <div className="def__label__box">
                <div className="rectangle"></div>
                <p className="label">{data.find(item => item.Key === "materiais-main-label").Value}</p>                           
              </div>          
              <div className="wrapper">
                <AreaList data={data} selectedIndex={selectedIndex} handleListItemClick={handleListItemClick} />
                <TabsComponent data={data} selectedIndex={selectedIndex} />
                {data.filter((item) => item.Key.includes("materiais-grid-image")).map((item, index) => (
                  <div key={index} className="img" style={{background: `url(${item.Value}) no-repeat center/cover`}}></div>
                ))}
              </div>
            </section>
          </>
        ) : null}
      </div>
    </Layout>
  );
}

export default MateriaisPage;